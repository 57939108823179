import { $t } from 'o365-utils';
export default class FullText{
   
    fnc:string;
    
    column:string;
  
    constructor(pColumn:string,pSearchFunction:string){
        this.fnc = pSearchFunction;
        this.column = pColumn;
    }

    getFilterString(pValue:string){
        return this.getFullTextSearchString(pValue);
    }

    getPrettyString(pValue:string){
        //return `${this.currentoptionTitle} (${pValue})`;

    }

    escapeFullTextString(str:string) {
        return `"${str.replaceAll('"', '""')}*"`
    }

    splitAndEscapeFullTextString(str:string| string[], keyword:string) {
    if (Array.isArray(str)) {
        str = str.join(' ');  
    }

    if (typeof str !== 'string') {
        return
    }

    const words = str.split(' ').filter(s => s);

    return words.map(this.escapeFullTextString).join(` ${keyword} `);
    }

    getFullTextSearchString(searchString:string){
        /*if ( this._currentOption == "contains") {
            return this.escapeFullTextString(searchString);
        } else if ( this._currentOption == "contains_all") {
            return this.splitAndEscapeFullTextString(searchString, 'AND');
        } else if ( this._currentOption == "contains_any") {
            return this.splitAndEscapeFullTextString(searchString, 'OR');
        } else {
            return searchString;
        }*/
    }

   /*  setFullTextSearchString(searchString:string|null){
        if(!searchString){
            this.value = null;
            return;
        }
        this.searchString = this.getFullTextSearchString(searchString);
    }

    setFromUrl(pOptions:Array<any>){
        this.currentOption = this.options[pOptions[1]]?.name;
        this.value = pOptions[2];
    }
    setFromSavedString(pOptions:any){
        this.currentOption = pOptions.column.replace("_FULL_TEXT_","");
        this.value = pOptions.value;
    } */

   /*  setFromExternal(pOptions:any){
        if(pOptions.constructor == Array){
            this.setFromUrl(pOptions);
            return
        }

        this.setFromSavedString(pOptions);
    } */

    

    
}